<template>
  <div class="swiper swiper-container" id="intro-swiper" style="flex-shrink: 0">
    <div class="swiper-wrapper">
      <div
        class="swiper-slide"
        v-for="(item, index) in hotChannelList"
        :key="index"
      >
        <div
          class="channel-card flex-column"
          @click.stop.prevent="handleJump(item)"
        >
          <img :src="item.image[0].img" style="width: 100%" class="cover" />
          <div class="intro vc">
            <img :src="item.logo" alt="" />
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-list">
      <van-button class="swiper-pre"></van-button>
      <van-button class="swiper-next"> </van-button>
    </div>

    <div class="swiper-pagination"></div>
    <div class="swiper-scrollbar"></div>
  </div>
</template>

<script>
import Swiper from "swiper";
import {
  Autoplay,
  EffectCoverflow,
  Pagination,
  Navigation,
} from "swiper/modules";
import "swiper/swiper.css";

export default {
  data() {
    return {
      hotChannelList: [],
      swiper: null,
    };
  },
  created() {
    //获取推荐频道列表
    // this.$http
    //   .get("/channel-manage/center-channel?page=1&per_page=3")
    //   .then(({ data }) => {
    //     this.hotChannelList = data.data;
    //   });
  },
  mounted() {
    this.$http
      .get("/channel-manage/center-channel?page=1&per_page=3")
      .then(({ data }) => {
        this.hotChannelList = [...data.data, ...data.data];
        this.$nextTick(() => {
          if (window.innerWidth < 768) {
            this.swiper = new Swiper("#intro-swiper", {
              modules: [Pagination, Navigation],
              // loop: true,
              // slidesPerView: 1,
              // initialSlide: 0,
              spaceBetween: 30,
              navigation: {
                nextEl: ".swiper-next",
                prevEl: ".swiper-pre",
              },
            });
          } else {
            this.swiper = new Swiper("#intro-swiper", {
              modules: [EffectCoverflow],
              effect: "coverflow",
              coverflowEffect: {
                rotate: 0,
                depth: 270,
                slideShadows: false,
                stretch: "25%",
              },
              loop: true,
              // loopedSlides: 4,
              // initialSlide: 0,
              slidesPerView: 3,
            });
          }
        });
      });
  },
  methods: {
    handleNext() {
      this.swiper.slideNext();
    },
    handlePre() {
      this.swiper.slidePrev();
    },
    handleJump({ channel_id }) {
      // this.$router.push({ path: "/micro-app/take-out" });
      // return;
      this.$router.push({
        path: "/channel/view",
        query: { id: channel_id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#intro-swiper {
  $slide-width: 1284px;
  width: 100vw;
  overflow: visible;
  .swiper-wrapper {
    // width: 80%;
    // width: 1284px;
  }
  .swiper-next,
  .swiper-pre {
    display: none;
  }
  .swiper-slide {
    width: 1284px;
    visibility: hidden;
    cursor: pointer;
  }
  .swiper-slide-visible,
  .swiper-slide-active,
  .swiper-slide-next {
    visibility: visible !important;
  }
  .channel-card {
    width: 1284px;
    height: 762px;
    background: #f9f9f9;
    box-shadow: 0px 40px 100px 0px rgba(173, 174, 198, 0.6);
    border-radius: 40px;
    overflow: hidden;
    .cover {
      width: 100%;
      height: 518px;
      flex-shrink: 0;
    }
    .intro {
      flex-grow: 1;
      padding-left: 60px;
      img {
        width: 120px;
        height: 120px;
        margin-right: 40px;
      }
      span {
        font-size: 52px;
        font-weight: 500;
        color: #333333;
        line-height: 56px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #intro-swiper {
    width: 85.6vw;
    overflow: visible;
    .swiper-wrapper {
      width: 100%;
    }
    .swiper-next,
    .swiper-pre {
      display: block;
    }
    .btn-list {
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      column-gap: 12vw;
      transform: translateY(5.6vw);
      width: 100%;
    }
    .swiper-next,
    .swiper-pre {
      background: transparent;
      background-size: 100% 100%;
      border: 0;
      width: 6.6vw;
      height: 6.7vw;
      padding: 0;
    }
    .swiper-next {
      background-image: url("../../assets/img/next-btn.png");
    }
    .swiper-pre {
      background-image: url("../../assets/img/pre-btn.png");
    }
    .swiper-slide {
      width: 100%;
      height: auto;
      visibility: visible;
    }

    .channel-card {
      width: 100%;
      height: fit-content;
      background: #f9f9f9;
      box-shadow: 0px 40px 100px 0px rgba(173, 174, 198, 0.6);
      border-radius: 2.67vw;
      overflow: hidden;
      .cover {
        width: 100%;
        height: 34.53vw;
        flex-shrink: 0;
      }
      .intro {
        flex-grow: 1;
        padding-left: 4vw;
        height: 16.27vw;
        img {
          width: 8vw;
          height: 8vw;
          margin-right: 2.67vw;
        }
        span {
          font-size: 4vw;
          line-height: 3.73vw;
        }
      }
    }
  }
}
</style>
